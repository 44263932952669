/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  memo,
  FC,
  useMemo,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { useRouter } from 'next/router';
import type {
  DatoProductImage,
  DatoAvailableLocales,
  Variant,
  DatoProductSpecs,
  Services,
} from '@framework/api/types';
import { getRegion } from '@framework/api/utils/maxify';
import { useTranslation as t, useCountdown } from '@utils/hooks';
import GTM from '@utils/gtm';
import getDefaultCountdownVariant from '@utils/product/get-default-countdown-var';
import { ROUTE_PRODUCT } from '@constants';
import {
  CardWrapper,
  CardColorVariants,
  CardPrice,
  CardExtendedDetails,
  CardContentWrapper,
  UpperCard,
} from '@components/CardComponents';
import ButtonLink from '@components/ButtonLink';
import CountdownTimer from '@components/CardComponents/CountdownTimer';
import slugify from '@utils/slugify';
import { Experiment } from '@amplitude/experiment-js-client';
import * as styles from './styles';

export type Props = {
  currencyCode: string;
  name: string;
  slug: string;
  productPrice?: number;
  variants: Variant[];
  shortDescription?: string;
  collectionImage?: DatoProductImage;
  isNude?: boolean;
  availableRegions: DatoAvailableLocales[];
  isExtendedCard?: boolean;
  techSpecs?: DatoProductSpecs[];
  noVariantToggle?: boolean;
  isSmall?: boolean;
  regionalServices?: Services;
  eventType?: string;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
  hasBackground?: boolean;
  rating?: number;
  isDark?: boolean;
  hybrid?: boolean;
  forceShowPromos?: boolean;
};

const amplitudeId = process.env.NEXT_PUBLIC_AMPLITUDE_ID;

const ProductCard: FC<Props> = ({
  currencyCode,
  name,
  slug,
  variants: rawVariants,
  shortDescription,
  collectionImage,
  isNude,
  availableRegions,
  techSpecs,
  isExtendedCard = false,
  noVariantToggle = false,
  isSmall = false,
  regionalServices,
  eventType,
  setIsLoading,
  hasBackground,
  rating,
  isDark = false,
  hybrid = false,
  forceShowPromos = false,
}) => {
  const { locale, asPath } = useRouter();
  const region = getRegion(locale);
  const isRegionallyAvailable = useMemo(
    () =>
      Array.isArray(availableRegions)
        ? availableRegions.some(r => r.region === region)
        : false,
    [region, availableRegions]
  );

  const [experimentVariant, setExperimentVariant] = useState(null);

  useEffect(() => {
    const initExperiment = async (): Promise<void> => {
      const experiment =
        Experiment.initializeWithAmplitudeAnalytics(amplitudeId);

      await experiment.start();

      const variant = experiment.variant('2025_02_11_prebuild_black_white');

      if (variant && variant.value) {
        setExperimentVariant(variant.value);
      }
    };

    if (amplitudeId) {
      initExperiment();
    }
  }, [asPath]);

  const abcBlackPrebuild = experimentVariant;

  const variants = useMemo(() => {
    const isPlayerSeries = [
      'player-one',
      'player-two',
      'player-three',
    ].includes(slug.toLowerCase());

    if (
      !isPlayerSeries ||
      abcBlackPrebuild === null ||
      abcBlackPrebuild === 'control'
    ) {
      return rawVariants;
    }

    if (['treatment', 'treatment-2'].includes(abcBlackPrebuild)) {
      const filtered = rawVariants.filter(
        variant =>
          !(
            variant.product_category.toLowerCase() === 'prebuilds' &&
            variant.sku.endsWith('-WH')
          )
      );
      return filtered.length > 0 ? filtered : rawVariants;
    }

    return rawVariants;
  }, [rawVariants, abcBlackPrebuild, slug]);

  // Ensure we have a valid initial variant
  const initialVariant = useMemo(() => {
    const validVariant = variants.find(v => v.isValid);
    return validVariant || variants[0];
  }, [variants]);

  const [selectedVariant, setSelectedVariant] = useState(initialVariant);

  const countdownData = useCountdown(selectedVariant?.countdownEndDate);

  const variantsCopy = [...variants];
  const variantsCountdownCopy = [...variants];
  const hasCountdown =
    variantsCountdownCopy?.filter(itm => itm?.showCountdownTimer)?.length > 0;

  // if countdown bypass default functionality
  // if no countdown, default functionality
  useEffect(() => {
    if (hasCountdown) {
      const countdownVars = variantsCountdownCopy?.filter(
        itm => itm?.showCountdownTimer
      );

      setSelectedVariant(getDefaultCountdownVariant(countdownVars, variants));
    } else if (variants[0].isOutOfStock || !variants[0]?.isValid) {
      const isInStock = variantsCopy.find(item => !item.isOutOfStock);
      const indexOfInStock = variants.indexOf(isInStock);
      const selectedIndex = indexOfInStock >= 0 ? indexOfInStock : 0;
      setSelectedVariant(variants[selectedIndex]);
    } else {
      setSelectedVariant(variants[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variants]);

  const techSpecsSlimmed =
    Array.isArray(techSpecs) &&
    techSpecs?.length > 0 &&
    Object.values(techSpecs[0]?.specValues).slice(0, 3);

  const techSpecsLabel =
    Array.isArray(techSpecs) &&
    techSpecs?.length > 0 &&
    techSpecs[0]?.specCategory;

  const SHOP_LABEL = t('label_shop');

  const ecommerce = selectedVariant
    ? {
        click: {
          products: [
            {
              name,
              id: selectedVariant.id,
              price: selectedVariant?.price?.toString() ?? null,
              brand: 'NZXT',
              variant:
                selectedVariant?.color?.map(color => color.name).join('/') ??
                null,
            },
          ],
        },
      }
    : null;

  const handleEvent = (): void => {
    if (eventType) {
      GTM.dataLayer({
        dataLayer: {
          event: eventType,
          interactionType: 'productCard',
          ecommerce,
        },
      });
    }
  };

  const showCountdown =
    selectedVariant?.showCountdownTimer &&
    isRegionallyAvailable &&
    !selectedVariant?.isComingSoon;

  return name && slug ? (
    <CardWrapper
      id={`${slug}-product-card`}
      data-test-id={`${slugify(name)}-product-card`}
    >
      <UpperCard
        hybrid={hybrid}
        name={name}
        slug={slug}
        variants={variants}
        shortDescription={shortDescription}
        collectionImage={collectionImage}
        isNude={isNude}
        availableRegions={availableRegions}
        isExtendedCard={isExtendedCard}
        isSmall={isSmall}
        regionalServices={regionalServices}
        eventType={eventType}
        selectedVariant={selectedVariant}
        setIsLoading={setIsLoading}
        currencyCode={currencyCode}
        hasBackground={hasBackground}
        showCountdown={showCountdown}
        rating={rating}
        isDark={isDark}
        forceShowPromos={forceShowPromos}
      />

      <CardContentWrapper>
        {isExtendedCard && (
          <CardExtendedDetails
            extendedHeading={techSpecsLabel}
            extendedDetails={techSpecsSlimmed}
          />
        )}

        {!isExtendedCard && (
          <div className={styles.descGrid}>
            <CardPrice
              currencyCode={currencyCode}
              slug={slug}
              handleClick={handleEvent}
              selectedVariant={selectedVariant}
              isRegionallyAvailable={isRegionallyAvailable}
              isSmall={isSmall}
            />

            {!isNude && (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <CardColorVariants
                isRegionallyAvailable={isRegionallyAvailable}
                variants={variants}
                selectedVariant={selectedVariant}
                setSelectedVariant={setSelectedVariant}
                productName={name}
                slug={slug}
                handleClick={handleEvent}
                isFaux={noVariantToggle}
                isSmall={isSmall}
                hasBackground={hasBackground}
                hybrid={hybrid}
              />
            )}
            {selectedVariant?.countdownEndDate && (
              <CountdownTimer
                isOutOfStock={selectedVariant?.isOutOfStock}
                hasBackground={hasBackground}
                countdownData={{
                  showCountdown,
                  countdownEndDate: countdownData,
                }}
              />
            )}
          </div>
        )}
        {isExtendedCard && (
          <div className={styles.fullWidth}>
            <div className={styles.extendedDetailsWrapper(true)}>
              <CardPrice
                currencyCode={currencyCode}
                slug={slug}
                handleClick={handleEvent}
                selectedVariant={selectedVariant}
                isRegionallyAvailable={isRegionallyAvailable}
                isSmall={isSmall}
                hasBackground={hasBackground}
              />
              {selectedVariant?.countdownEndDate && (
                <CountdownTimer
                  isOutOfStock={selectedVariant?.isOutOfStock}
                  hasBackground={hasBackground}
                  countdownData={{
                    showCountdown,
                    countdownEndDate: countdownData,
                  }}
                />
              )}
            </div>

            <div className={styles.extendedDetailsWrapper(false)}>
              <ButtonLink
                asButton
                buttonStyle="primary"
                href={`/${ROUTE_PRODUCT}/${slug}`}
                className={styles.buttonLabel(isSmall)}
                customMargin
                internalLink
              >
                {SHOP_LABEL}
              </ButtonLink>
            </div>
          </div>
        )}
      </CardContentWrapper>
    </CardWrapper>
  ) : null;
};

export default memo(ProductCard);
